$themes: (
    'blue': $blue,
    'cyan': $cyan,
    'dark': $dark,
    'green': $green,
    'indigo': $indigo,
);

@each $theme, $color in $themes {
    .theme-#{$theme} {
        @include bg-variant('.bg-theme', $color);
        @include bg-gradient-variant('.bg-gradient-theme', $color);

        .border-bottom-theme {
            border-bottom: .25rem solid $color !important;
        }

        .btn-theme {
            @include button-variant($color, $color);
        }

        .btn-outline-theme {
            @include button-outline-variant($color, $white);
        }

        .text-theme {
            color: $color !important;
        }
    }

    .bg-#{$theme} {
        background-color: $color !important;
    }

    .bg-hover-#{$theme}:hover {
        color: $light !important;
        background-color: $color !important;
    }

    .text-#{$theme} {
        color: $color;
    }
}
